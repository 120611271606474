import axios from 'axios';

const controller = 'PortalCustomizacoes';
const url = process.env.VUE_APP_ROOT_API + controller;

const CustomLogo = {
  GetCustomLogo: async function (path) {
    let slug = null;

    if (window.localStorage.user) {
        slug = JSON.parse(window.localStorage.user).slug || null;
    }

    if (path.includes('login')) {
        slug = path.split('/')[2];
    }

    if (slug) {
      const storedLogo = localStorage.getItem('customLogo');
      if (storedLogo) {
        return storedLogo;
      }

      const response = await axios.get(`${url}/Logo`, { params: { slug } });

      if (response.data && response.data.imagem) {
        const logoData = `data:image/png;base64,${response.data.imagem}`;
        localStorage.setItem('customLogo', logoData);
        return logoData;
      }
    }

    return null;
  }
};

export default CustomLogo;